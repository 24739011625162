@use '../../styles/yr' as *;

$width: size(1);
$corner-height: size(4);

.scroll-shadow {
  position: relative;
  width: $width;
  height: 100%;
  background-size: 100% calc(100% - #{2 * $corner-height});
  background-position: 0 $corner-height;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: opacity $ui-scroll-shadow-transition;
}

.scroll-shadow:before,
.scroll-shadow:after {
  display: block;
  content: '';
  position: absolute;
  height: $corner-height;
  width: 100%;
}

.scroll-shadow:before {
  top: 0;
}

.scroll-shadow:after {
  bottom: 0;
}

.scroll-shadow--left {
  // TODO(as): There might be a solution to our gradient problem somewhere in this Github issue: https://github.com/w3c/csswg-drafts/issues/6880#issuecomment-991947499
  //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  //background-image: linear-gradient(to right, var(--color-fill-base), $theme-background-primary-transparent);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0));

  &:before {
    background-image: radial-gradient(at 0% 100%, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0) $width);
  }

  &:after {
    background-image: radial-gradient(at 0% 0%, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0) $width);
  }
}

.scroll-shadow--right {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0));

  &:before {
    background-image: radial-gradient(at 100% 100%, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0) $width);
  }

  &:after {
    background-image: radial-gradient(at 100% 0%, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0) $width);
  }
}
